
/*
================================================================================
navigation
================================================================================
*/

.search-tool__nav-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 70px;
  left: 250px;
  right: 0px;
  background-color: white;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  padding-left: 16px;
  z-index: 1000;
}

.search-tool__count-results {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  font-size: 14px;
  color: var(--text-medium);
  font-weight: 400;
  height: 70px;
  width: 100px;
  padding-right: 32px;
}

/*
================================================================================
results
================================================================================
*/

.search-tool__result-number {
  color: var(--primary-dark);
  margin-right: 16px;
  font-size: 28px;
}

.search-tool__result-tag-list {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}

.search-tool__result-tag {
  margin-right: 16px !important;
  margin-top: 16px !important;
}

.search-tool__decision-title {
  color: var(--primary-dark);
  font-size: 28px;
  font-weight: 600;
}

.search-tool__decision-sub-title {
  color: var(--primary-dark);
  font-size: 18px;
  font-weight: 600;
  margin-top: 32px;
}

.search-tool__vote-button-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 32px;
}

/*
================================================================================
votes
================================================================================
*/

.search-tool__vote-grid {
  -webkit-column-count: 2;
  column-count: 2;
  width: 100%;
}

.search-tool__vote-grid--margin {
  -webkit-column-count: 2;
  column-count: 2;
  margin-bottom: 32px;
  width: 100%;
}

.search-tool__vote-grid--one-column {
  width: 100%;
}

.search-tool__vote-item {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-end;
  margin-bottom: 12px;
  max-width: 250px;
}

.search-tool__vote-item--one-column {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-end;
  margin-bottom: 12px;
}

.search-tool__member-state {
  font-size: 16px;
  color: var(--text-medium);
  font-weight: 300;
  padding-left: 16px;
  text-indent: -16px;
  line-height: 1.2;
}

.search-tool__vote {
  font-size: 14px;
  font-weight: 500;
  color: var(--primary);
}

.search-tool__vote-title {
  color: var(--primary-dark);
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 32px;
}

.search-tool__vote-sub-title {
  color: var(--primary-dark);
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 32px;
}

.search-tool__vote-caption {
  color: var(--primary-dark);
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 16px;
}

/*
================================================================================
message
================================================================================
*/

.search-tool__no-results-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.search-tool__no-results-message {
  width: 600px;
  color: var(--primary);
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  border: solid 1px var(--primary);
  background-color: var(--primary-background);
  border-radius: 8px;
}

/*
================================================================================
progress bar
================================================================================
*/

.search-tool__progress-bar-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 70px;
}

.search-tool__progress-bar {
  width: 600px;
}

/*
================================================================================
map
================================================================================
*/

.search-tool__map-title {
  text-align: left;
  font-size: 28px;
  color: var(--primary-dark);
  font-weight: 600;
  width: calc(800px - 16px - 16px);
  margin-bottom: 16px;
}

.search-tool__map-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;
}

.search-tool__map-caption {
  font-size: 14px;
  color: var(--text-medium);
  line-height: 1.5;
  font-weight: 300;
  margin-top: 16px;
  width: 100%;
}
