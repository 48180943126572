
/*
================================================================================
variables
================================================================================
*/

:root {
  --primary: rgba(97, 168, 236, 1);
  --primary-hover: rgba(78, 150, 212, 1);
  --primary-background: rgba(97, 168, 236, 0.1);
  --primary-background-hover: rgba(97, 168, 236, 0.3);
  --primary-medium: rgba(97, 168, 236, 0.5);
  --primary-dark: rgba(78, 150, 212, 1);
  --background: rgba(245, 247, 251, 1);
  --line: rgba(0, 0, 0, 0.1);
  --text-light: rgba(0, 0, 0, 0.3);
  --text-medium: rgba(0, 0, 0, 0.5);
  --text-dark: rgba(0, 0, 0, 0.75);
  --text-code: rgba(67, 116, 163, 1);
  --background-code: rgba(97, 168, 236, 0.1);
  --line-code: rgba(97, 168, 236, 0.5);
  --shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
  --shadow-hover: 0px 0px 25px 0px rgba(64, 71, 80, 0.4);
  --form-spacing-vertical: 16px;
  --form-spacing-horizontal: 8px;
  --text-spacing: 10px;
}

/*
================================================================================
font
================================================================================
*/

* {
  font-family: "Inter";
}

/*
================================================================================
basic elements
================================================================================
*/

html, body {
  margin: 0;
  padding: 0;
}

pre {
  display: flex;
  margin: 0px;
  overflow: auto;
  white-space: pre;
}

.bold {
  font-weight: 600;
  color: var(--text-dark);
}

.bold--primary {
  font-weight: 600;
  color: var(--primary);
}

.italic {
  font-weight: 300;
  font-style: italic;
}

.link {
  color: var(--primary);
  cursor: pointer;
  font-weight: 500;
}

.code {
  background-color: var(--background-code);
  border: 1px solid var(--line-code);
  color: var(--text-code);
  font-family: "Source Code Pro";
  font-size: 14px;
  font-weight: 400;
  border-radius: 4px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 6px;
  padding-right: 6px;
  white-space: nowrap;
}

.code-block {
  font-family: "Source Code Pro";
  background-color: var(--background-code);
  border: 1px solid var(--line-code);
  color: var(--text-code);
  font-size: 10pt;
  font-weight: 400;
  border-radius: 8px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 1.8;
  width: calc(100% - 32px - 2px);
}

@media only screen and (max-width: 768px) {
  .code-block {
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100vw - 64px - 2px);
  }
}

.divider {
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.quote {
  font-size: 15px;
  font-weight: 300;
  line-height: 1.7;
  color: var(--text-medium);
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-left: 6px solid var(--primary);
  margin-left: 16px;
  overflow-x: scroll;
}

.paragraph {
  font-weight: 300;
  font-size: 16px;
  line-height: 1.7;
  margin-top: 10px;
  margin-bottom: 10px;
  color: var(--text-dark);
}

.title {
  font-weight: 600;
  color: var(--primary-dark);
  font-size: 32px;
  margin-bottom: 6px;
  line-height: 1.3;
}

@media only screen and (max-width: 768px) {
  .title {
    font-size: 28px;
  }
}

.sub-title {
  font-weight: 600;
  color: var(--primary-dark);
  font-size: 24px;
  margin-top: 50px;
  margin-bottom: 8px;
}

@media only screen and (max-width: 768px) {
  .sub-title {
    font-size: 22px;
  }
}

.spacer--16 {
  height: 16px;
}

.spacer--32 {
  height: 32px;
}

.spacer--50 {
  height: 32px;
}

/*
================================================================================
dashboard
================================================================================
*/

.dashboard {
  background-color: white;
  min-height: 100vh;
  min-width: 100vw;
}

/*
================================================================================
panels
================================================================================
*/

.panel {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* top set by JS */
  bottom: 0px;
  /* left set by JS */
  right: 0px;
  overflow-y: scroll;
}

@media only screen and (max-width: 768px) {
  .panel {
    left: 0px;
  }
}

.panel__content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.panel__section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.panel__section--full-page {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-height: calc(100vh - 70px - 70px - 2px);
}

.panel__section--full-page-toolbar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-height: calc(100vh - 70px - 70px - 70px - 2px);
}

.panel__footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 70px;
  border-top: solid 1px rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.2);
  font-size: 13px;
}

/*
================================================================================
sidebar
================================================================================
*/

.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  width: 100%;
  height: 100%;
}

.sidebar__content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 32px;
  padding-left: 16px;
  padding-right: 16px;
  width: calc(100% - 32px);
}

.sidebar__item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 4px;
  margin-bottom: 4px;
  border-radius: 8px;
  cursor: pointer;
  color: var(--text-medium);
  font-weight: 400;
  font-size: 15px;
  text-decoration: none;
  flex-grow: 1;
  transition-duration: 0.2s;
}

.sidebar__item:hover {
  background-color: var(--background);
}

.sidebar__item--selected {
  background-color: var(--primary);
  color: white;
}

.sidebar__item--selected:hover {
  background-color: var(--primary-hover);
  color: white;
}

.sidebar__icon {
  padding-right: 24px;
  color: var(--primary);
}

.sidebar__item--selected .sidebar__icon {
  color: white;
}

/*
================================================================================
menu
================================================================================
*/

.menu {
  position: fixed;
  top: 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
}

.menu__logo-container {
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-decoration: none;
}

.menu__logo-text {
  font-size: 18px;
  color: var(--primary-dark);
  font-weight: 300;
  line-height: 1;
}

.menu__logo-text--large {
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 1.5px;
  line-height: 1;
}

@media only screen and (max-width: 768px) {
  .menu__logo-text--large {
    font-size: 22px;
  }
}

.menu__search-container {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 70px;
  padding-left: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  z-index: 2100;
}

/*
================================================================================
authentication panel
================================================================================
*/

.auth-panel {
  position: fixed;
  top: 0px;
  right: 0px;
  padding-right: 32px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  white-space: nowrap;
  z-index: 2100;
}

@media only screen and (max-width: 768px) {
  .auth-panel {
    display: none;
  }
}

.auth-panel__username-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  white-space: nowrap;
  color: var(--primary);
  border-right: solid 1px rgba(0, 0, 0, 0.1);
  padding-top: 2px;
  padding-bottom: 2px;
}

.auth-panel__username {
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 16px;
  padding-left: 16px;
  border-right: 1px solid white;
  color: var(--primary);
  font-size: 10pt;
  font-weight: 500;
}

.auth-panel__button {
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 16px;
  cursor: pointer;
  color: var(--primary);
  font-size: 10pt;
  text-decoration: none;
  font-weight: 300;
  opacity: 1;
}

@media only screen and (max-width: 768px) {
  .auth-panel__button {
    padding-left: 0px;
  }
}

.auth-panel__button:hover {
  opacity: 0.7;
}

/*
================================================================================
login form
================================================================================
*/

.login-form__left-panel {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  width: 60vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-form__right-panel {
  position: fixed;
  top: 0px;
  bottom: 0px;
  right: 0px;
  width: 40vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-form__image {
  height: 100%;
  width: calc(100% - 32px);
  margin-top: 16px;
  margin-bottom: 16px;
  margin-left: 16px;
  margin-right: 16px;
  border-radius: 8px;
  object-fit: cover;
}

.login-form__message {
  color: var(--primary);
  font-size: 14px;
  font-weight: 300;
  margin-top: 32px;
  height: 50px;
}

.login-form__message--hidden {
  margin-top: 32px;
  height: 50px;
  opacity: 0;
}

/*
================================================================================
forms
================================================================================
*/

.form__grid--left {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: var(--form-spacing-vertical);
  margin-bottom: var(--form-spacing-vertical);
}

.form__grid--center {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: var(--form-spacing-vertical);
  margin-bottom: var(--form-spacing-vertical);
}

.form__grid--right {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: var(--form-spacing-vertical);
  margin-bottom: var(--form-spacing-vertical);
}

.form__row--left {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-top: calc(var(--form-spacing-vertical) / 2);
  margin-bottom: calc(var(--form-spacing-vertical) / 2);
}

@media only screen and (max-width: 768px) {
  .form__row--left {
    flex-direction: column;
  }
}

.form__row--center {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-top: calc(var(--form-spacing-vertical) / 2);
  margin-bottom: calc(var(--form-spacing-vertical) / 2);
}

@media only screen and (max-width: 768px) {
  .form__row--center {
    flex-direction: column;
  }
}

.form__row--right {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
  margin-top: calc(var(--form-spacing-vertical) / 2);
  margin-bottom: calc(var(--form-spacing-vertical) / 2);
}

@media only screen and (max-width: 768px) {
  .form__row--right {
    flex-direction: column;
  }
}

.form__grid-fragment {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.form__element-container--left {
  margin-top: calc(var(--form-spacing-vertical) / 2);
  margin-bottom: calc(var(--form-spacing-vertical) / 2);
  margin-right: calc(var(--form-spacing-horizontal) * 2);
}

.form__element-container--center {
  margin-top: calc(var(--form-spacing-vertical) / 2);
  margin-bottom: calc(var(--form-spacing-vertical) / 2);
  margin-left: var(--form-spacing-horizontal);
  margin-right: var(--form-spacing-horizontal);
}

.form__element-container--right {
  margin-top: calc(var(--form-spacing-vertical) / 2);
  margin-bottom: calc(var(--form-spacing-vertical) / 2);
  margin-left: calc(var(--form-spacing-horizontal) * 2);
}

.form__button-container--center {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-top: var(--form-spacing-vertical);
  margin-bottom: var(--form-spacing-vertical);
}

/*
================================================================================
blocks
================================================================================
*/

.block {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 70px;
  margin-bottom: 70px;
  width: 750px;
}

/* .block {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 16px;
  margin-bottom: 16px;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 50px;
  padding-right: 50px;
  width: 800px;
  background-color: white;
  border-radius: 8px;
  box-shadow: var(--shadow);
} */

@media only screen and (max-width: 768px) {
  .block {
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 50px;
    margin-bottom: 50px;
    max-width: 100vw;
  }
}

.block--gray {
  background-color: var(--background);
}

.block--color {
  background-color: var(--primary);
}

/*
================================================================================
buttons
================================================================================
*/

.MuiTouchRipple-root {
  display: none !important;
  opacity: 0 !important;
}

.MuiButton-root {
  border-radius: 8px;
  box-shadow: none;
  white-space: nowrap;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  height: 38px;
}

.MuiButton-root:hover {
  box-shadow: none;
  background-color: var(--primary-hover);
  border-color: var(--primary-hover);
}

.MuiButton-contained {
  border-radius: 8px;
  box-shadow: none;
  background-color: var(--primary);
  border: 1px solid var(--primary);
  color: white;
  white-space: nowrap;
}

.MuiButton-contained:hover {
  background-color: var(--primary-hover);
  border-color: var(--primary-hover);
}

.MuiButton-outlined {
  padding-left: 16px;
  padding-right: 16px;
  min-width: unset;
  background-color: var(--primary-background);
  border: 1px solid var(--primary);
  color: var(--primary);
}

.MuiButton-outlined:hover {
  background-color: var(--primary-background-hover);
}

.MuiButton-text {
  padding-left: 16px;
  padding-right: 16px;
  min-width: unset;
  background-color: var(--primary-background);
  border: none;
  color: var(--primary);
}

.MuiButton-text:hover {
  background-color: var(--primary-background-hover);
}

/*
================================================================================
chips
================================================================================
*/

.MuiChip-root {
  font-family: "Inter";
  color: var(--text-medium);
  font-size: 13px;
  background-color: rgba(0, 0, 0, 0.06);
  font-weight: 400;
  padding-top: 4px;
  padding-bottom: 4px;
}

.MuiChip-root .MuiChip-icon {
  color: rgba(0, 0, 0, 0.15);
}

/*
================================================================================
select
================================================================================
*/

/* the text of the select field when blank */
.MuiFormLabel-root.MuiInputLabel-root {
  font-family: "Inter";
  font-size: 15px;
  color: var(--text-medium);
  font-weight: 300;
  transform: translate(22px, 23px) scale(1);
}

/* the text of the label, which moves up and shrinks */
.MuiFormLabel-root.MuiInputLabel-shrink {
  font-family: "Inter";
  color: var(--primary);
  transform: translate(24px, 11px) scale(0.8);
}

/* the text you type into the field */
.MuiInputBase-input.MuiFilledInput-input {
  font-family: "Inter";
  color: var(--text-medium);
  font-weight: 300;
  padding-top: 22px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
}

/* the outer border of the field */
.MuiInputBase-root.MuiFilledInput-root {
  font-family: "Inter";
  background-color: var(--background);
  border: 1px solid rgba(0, 0, 0, 0.05);
  font-weight: 300;
  border-radius: 8px;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 23px;
  padding-right: 23px;
  transition: border-color 0.3s;
}

/* border when select field is focused */
.MuiInputBase-root.MuiFilledInput-root.Mui-focused {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 22px;
  padding-right: 22px;
  border: 2px solid var(--primary);
}

/* remove border under select field */
.MuiInputBase-root.MuiFilledInput-root:before {
  border-bottom: none !important;
}

/* remove border under select field */
.MuiInputBase-root.MuiFilledInput-root:after {
  border-bottom: none !important;
}

/* the helper text under the field */
.MuiFormHelperText-root {
  font-family: "Inter";
  font-weight: 300;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.4);
  margin-top: 6px;
  margin-left: 22px;
  margin-right: 22px;
}

/* background color of the select input area */
.MuiSelect-select.MuiInputBase-input.MuiFilledInput-input.MuiSelect-select {
  background-color: unset;
}

/* the select icon */
.MuiSelect-icon {
  color: var(--text-light);
  padding-right: 8px;
  padding-left: 8px;
  transition: transform 0.3s;
}

/*
================================================================================
select pop-up menu
================================================================================
*/

/* menu */
.MuiPopover-paper {
  box-shadow: var(--shadow);
  border-radius: 8px;
}

/* index of menu */
.MuiPopover-root {
  z-index: 6100;
}

/* list in menu */
.MuiMenu-list {
  padding-top: 0px;
  padding-bottom: 0px;
}

/* list item in menu */
.MuiMenuItem-root {
  font-family: "Inter";
  background-color: white;
  font-size: 15px;
  color: var(--text-medium);
  font-weight: 300;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 24px;
}

.MuiMenuItem-root:hover {
  background-color: var(--background);
}

.MuiMenuItem-root.Mui-selected {
  background-color: var(--primary);
  color: white;
}

.MuiMenuItem-root.Mui-selected:hover {
  background-color: var(--primary-hover);
  color: white;
}

/*
================================================================================
dialog
================================================================================
*/

.MuiPaper-root.MuiDialog-paper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 50vw;
  min-height: 50vh;
  border-radius: 8px;
  box-shadow: var(--shadow);
}

@media only screen and (max-width: 768px) {
  .MuiPaper-root.MuiDialog-paper {
    margin-left: 16px;
    margin-right: 16px;
  }
}

.dialog__header-container {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 70px;
  background-color: white;
  border-bottom: 1px solid var(--line);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: var(--primary);
  font-size: 28px;
  font-weight: 600;
  z-index: 1500;
}

.dialog__content-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: absolute;
  top: 70px;
  bottom: 70px;
  left: 0px;
  right: 0px;
  background-color: white;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 32px;
  padding-bottom: 32px;
  z-index: 1000;
  overflow-y: scroll;
}

.dialog__footer-container {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 70px;
  padding-right: 32px;
  background-color: white;
  border-top: 1px solid var(--line);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 1500;
}

/*
================================================================================
cover page
================================================================================
*/

.cover-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cover-page-title {
  color: white;
  font-weight: 500;
  font-size: 60px;
  padding-bottom: 50px;
  letter-spacing: 1px;
}

.cover-page-content {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  padding-bottom: 100px;
}

.cover-image {
  background-size: cover;
  height: 100vh;
}

/*
================================================================================
accordion
================================================================================
*/

.MuiPaper-root.MuiAccordion-root {
  box-shadow: none;
  background-color: unset;
}

.MuiPaper-root.MuiAccordion-root.Mui-expanded {
  margin: 0px;
}

.MuiButtonBase-root.MuiAccordionSummary-root {
  min-height: unset;
}

.MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded {
  min-height: unset;
}

.MuiPaper-root.MuiAccordion-root::before {
  height: 0px;
  top: 0;
}

.MuiButtonBase-root.MuiAccordionSummary-root {
  padding: 0px;
  flex-direction: column;
  align-items: flex-start;
}

.MuiAccordionSummary-content {
  margin: 0px;
  width: 100%;
}

.MuiAccordionSummary-content.Mui-expanded {
  margin: 0px;
  width: 100%;
}

.MuiAccordionSummary-expandIconWrapper {
  padding-left: 16px;
  padding-right: 16px;
}

.MuiAccordionDetails-root {
  padding: 0px;
}

/*
================================================================================
FAQs
================================================================================
*/

.faqs__list-container {
  margin-top: 50px;
}

.faqs__container {
  margin-bottom: 16px;
  width: 100%;
}

.faqs__question-container {
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 16px;
  padding-right: 16px;
  font-weight: 500;
  color: var(--primary);
  width: 100%;
  border: solid 1px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.faqs__answer-container {
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 16px;
  margin-left: 16px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

/*
================================================================================
tooltip
================================================================================
*/

.MuiTooltip-tooltip {
  background-color: rgba(0, 0, 0, 0.7);
  font-weight: 400;
  color: white;
  font-size: 12px;
  line-height: 1.3;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 4px;
  max-width: 325px;
}

.MuiTooltip-arrow {
  color: rgba(0, 0, 0, 0.7);
}

/*
================================================================================
table
================================================================================
*/

.MuiTableContainer-root {
  padding: 0px;
  margin: 0px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}

.MuiTableCell-root {
  font-family: "Source Code Pro";
  font-weight: 300;
  max-width: 250px;
  font-size: 14px;
  color: var(--text-code) !important;
  font-weight: 400;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  border-bottom: 1px solid #B0D4F6;
  border-right: 1px solid #B0D4F6;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  opacity: 0.8;
  background-color: rgba(97, 168, 236, 0.05);
}

.data-table {
  width: calc(100% - 2px);
  overflow-x: scroll;
  border: 1px solid var(--line-code);
  border-radius: 8px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}

.data-table__caption {
  font-size: 16px;
  color: var(--text-dark);
  margin-bottom: 24px;
  line-height: 1.5;
  font-weight: 300;
}

.data-table__header {
  background-color: #E7F2FC;
  color: var(--text-code) !important;
  font-weight: 600;
  opacity: 1;
}

/*
================================================================================
plot
================================================================================
*/

.plot__container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 32px;
  margin-bottom: 32px;
}

.plot__image {
  border: solid 1px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 16px;
  width: calc(100% - 32px);
}

.plot__caption {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.4);
  line-height: 1.3;
  font-weight: 300;
  margin-top: 16px;
  margin-left: 16px;
  margin-right: 16px;
}
