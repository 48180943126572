
/*
================================================================================
download tool
================================================================================
*/

.download-tool__container {
  padding-top: 50px;
}

@media only screen and (max-width: 768px) {
  .download-tool__container {
    display: none;
  }
}

/*
================================================================================
stepper
================================================================================
*/

.MuiStepLabel-label {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 600 !important;
  cursor: pointer;
}

.MuiStepLabel-label.Mui-active {
  color: var(--primary-dark);
  font-weight: 600 !important;
}

.MuiStepLabel-label.Mui-completed {
  color: var(--text-light);
}

.MuiStepLabel-label.Mui-disabled {
  color: var(--text-light);
}

.MuiStepLabel-vertical {
  padding-top: 10px;
  padding-bottom: 10px;
}

.MuiStepLabel-iconContainer {
  padding-right: 32px;
}

@media only screen and (max-width: 768px) {
  .MuiStepLabel-iconContainer {
    padding-right: 16px;
  }
}

.MuiStepIcon-root {
  color: var(--line);
  font-family: "Inter";
  font-weight: 400;
}

.MuiStepIcon-root.Mui-completed {
  color: var(--line);
}

.MuiStepIcon-root.Mui-active {
  color: var(--primary);
}

.MuiStepContent-root {
  padding-left: 42px;
  border-color: var(--line);
  border-width: 1px;
}

@media only screen and (max-width: 768px) {
  .MuiStepContent-root {
    padding-left: 28px;
  }
}

.MuiStepConnector-line {
  min-height: 32px;
  border-color: var(--line);
  border-width: 1px;
}


/*
================================================================================
checkbox
================================================================================
*/

.download-tool__checkbox-grid {
  padding-left: 32px;
  padding-top: 16px;
  padding-bottom: 16px;
  -webkit-column-count: 2;
  column-count: 2;
}

.download-tool__checkbox-container {
  width: 100%;
}

.MuiFormControlLabel-label {
  padding-left: 16px;
  font-weight: 400;
  font-size: 10pt;
  font-family: "Source Code Pro";
  padding-top: 0px;
  padding-bottom: 0px;
  color: var(--text-medium);
}

.MuiButtonBase-root.MuiCheckbox-root {
    color: rgba(0, 0, 0, 0.2);
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 0px;
    padding-right: 0px;
    background-color: unset;
}

.MuiButtonBase-root.MuiCheckbox-root.Mui-checked {
    color: var(--primary);
}

/*
================================================================================
progress bar
================================================================================
*/

.MuiLinearProgress-root {
  background-color: var(--primary-background);
}

.MuiLinearProgress-bar {
  background-color: var(--primary);
}

.download-tool__progress-bar-container {
  width: 100%;
  padding-top: 16px;
  padding-bottom: 12px;
  opacity: 1;
  transition: opacity 0.3s;
}

.download-tool__progress-bar-container--hidden {
  width: 100%;
  padding-top: 16px;
  padding-bottom: 12px;
  opacity: 0;
  cursor: default;
  transition: opacity 0.3s;
}

.download-tool__progress-bar-caption {
  color: var(--text-medium);
  font-size: 14px;
  font-weight: 300;
  margin-top: 16px;
}

/*
================================================================================
codebook
================================================================================
*/

.codebook__container {
  display: flex;
  flex-direction: column;
  padding-left: 20%;
  padding-right: 20%;
}

.codebook__dataset-container {
  padding-top: 32px;
  padding-bottom: 22px;
}

.codebook__divider {
  border-bottom: 1px solid var(--line);
}

.codebook__dataset-label {
  padding-top: 8px;
  padding-bottom: 22px;
  font-weight: 600;
  color: var(--primary);
  font-size: 18pt;
}

.codebook__dataset-name {
  font-family: "Source Code Pro";
  background-color: var(--background-code);
  border: 1px solid var(--line-code);
  color: var(--text-code);
  font-size: 10pt;
  font-weight: 400;
  border-radius: 4px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 6px;
  padding-right: 6px;
  white-space: nowrap;
}

.codebook__dataset-description {
  padding-top: 22px;
  font-weight: 300;
  font-size: 12pt;
  color: var(--text-dark);
  line-height: 1.8;
}

.codebook__variables-container {
  padding-top: 16px;
  padding-left: 16px;
}

.codebook__variable-container {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 16px;
}

.codebook__variable-header {
  padding-bottom: 16px;
}

.codebook__variable-body {
  padding-left: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  line-height: 1.8;
}

.codebook__variable-name {
  font-family: "Source Code Pro";
  background-color: var(--background-code);
  border: 1px solid var(--line-code);
  color: var(--text-code);
  font-size: 14px;
  font-weight: 400;
  border-radius: 4px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 6px;
  padding-right: 6px;
  white-space: nowrap;
  margin-right: 16px;
  margin-left: -32px;
}

.codebook__variable-type {
  font-family: "Source Code Pro";
  background-color: var(--background-code);
  border: 1px solid var(--line-code);
  color: var(--text-code);
  font-size: 14px;
  font-weight: 400;
  border-radius: 4px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 6px;
  padding-right: 6px;
  white-space: nowrap;
  margin-right: 16px;
}

.codebook__variable-description {
  font-weight: 300;
  font-size: 16px;
  color: var(--text-dark);
  line-height: 1.5;
}
