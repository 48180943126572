
.map-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.map {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  cursor: pointer;
}

.map .hovering {
  fill: #9C61E2 !important;
}

.map .selected {
  fill: #9C61E2 !important;
}

.map .selected-hovering {
  fill: #9C61E2 !important;
}

.map-tooltip {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 16px 16px;
  background-color: white;
  border: none;
  border-radius: 8px;
  color: black;
  box-shadow: 0px 0px 20px rgb(0, 0, 0, 0.2);
  pointer-events: none;
}

.map-tooltip__member-state {
  font-family: "Inter";
  font-size: 16px;
  color: var(--primary-dark);
  font-weight: 500;
}

.map-tooltip__not-member-state {
  font-family: "Inter";
  font-size: 16px;
  color: var(--text-medium);
  font-weight: 500;
}

.map-tooltip__info {
  font-family: "Inter";
  font-size: 14px;
  color: var(--text-medium);
  font-weight: 300;
  padding-top: 6px;
}
